<template>
  <v-navigation-drawer
      v-model="drawer"
      width="400"
      app
      temporary
      right
      class="wost-drawer"
  >
    <div class="navigation-head">
      <span>{{ titleForm }}</span>
    </div>

    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <template>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="filters.name" label="Nombre" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
                v-model="filters.description"
                outlined
                label="Descripción"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </template>
    </div>

    <div class="mt-3 pl-3 pt-3 pr-3">
      <template>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleClearFilters">
              <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
              Quitar filtros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark color="blue" block height="40" @click="handleSearch">
              <v-icon size="16" class="mr-1">fas fa-search</v-icon>
              Filtrar datos
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </div>

  </v-navigation-drawer>
</template>

<script>
import { mapActions} from 'vuex'

export default {
  name: 'Filters',
  data() {
    return {
      drawer: false,
      titleForm: null,
      filters: {
        name: null,
        description: null
      }
    }
  },

  methods: {
    ...mapActions('brands', [
      'getBrands', 'changeFilterPaginated'
    ]),
    showForm(title) {
      this.titleForm = title
      this.drawer = !this.drawer
    },
    closeForm() {
      this.drawer = false
    },
    clearData() {
      this.filters.name = null
      this.filters.description = null
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
    },
    customText(item){
      let text = item.name
      return text
    },
    async handleClearFilters() {
      this.clearData()
      await this.getBrands({...this.filters, paginated: true, itemsPerPage: -1})
    },
    async handleSearch() {
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
      await this.getBrands(this.filters)
      this.closeForm()
    }
  }
}
</script>

<style scoped>

</style>