<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Marcas</span>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('marcas.create')" dark color="#023145" width="140" class="float-right"
                 @click="handleShowCreate">
            Nuevo
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
              v-model="search_brand"
              label="Buscar"
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
              @keydown="handleInputSearchByEnter">
          </v-text-field>
        </v-col>
        <v-col cols="6" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn v-if="this.$hasPermision('marcas.filters')" dark color="#023145" outlined width="140"
                 class="float-right" @click="handleShowFilters">
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
                v-model="selectedRows"
                :headers="headers"
                :items="brands"
                :items-per-page="10"
                class="elevation-1 w-table"
                height="57vh"
                show-select
                :single-select="true"
                :loading="loading"
                :options.sync="options"
                :server-items-length="paginated.total"
                loading-text="Loading... Please wait"
                @item-selected="handleSelectItem"
            >
              <template v-slot:top>
                                <span class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las marcas
                                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.name="{ item }">
                <span class="font-weight-medium">{{ item.name }}</span>
                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
              </template>
<!--              <template v-slot:footer>-->
<!--                <div class="text-center">-->
<!--                  <v-container>-->
<!--                    <v-row justify="center">-->
<!--                      <v-col cols="12">-->
<!--                        <v-container class="text-right" style="float: right">-->
<!--                          <v-pagination-->
<!--                              :length="paginated.last_page"-->
<!--                              class="my-4"-->
<!--                              v-model="dataTable.page"-->
<!--                              @input="onPageChange"-->
<!--                          ></v-pagination>-->
<!--                        </v-container>-->
<!--                      </v-col>-->

<!--                    </v-row>-->
<!--                  </v-container>-->
<!--                </div>-->

<!--              </template>-->

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <div class="text-center">
      <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
        <v-sheet class="text-center" height="85px">
          <div class="py-3">
            <v-btn v-if="this.$hasPermision('marcas.edit')" class="mt-3" dark color="blue" outlined @click="handleEdit">
              <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
              Editar
            </v-btn>
            <v-btn v-if="show_btn_active && this.$hasPermision('marcas.change_status')" class="ml-4 mt-3" dark
                   color="green" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
              Activar
            </v-btn>
            <v-btn v-if="!show_btn_active && this.$hasPermision('marcas.change_status')" class="ml-4 mt-3" dark
                   color="amber" outlined @click="handleChangeStatus">
              <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
              Inactivar
            </v-btn>
            <v-btn v-if="this.$hasPermision('marcas.destroy')" class="ml-4 mt-3" dark color="red" outlined
                   @click="handleDelete">
              <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
              Eliminar
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <create-update ref="createUpdate"></create-update>
    <FiltersPaginated ref="filters"></FiltersPaginated>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import OfficeLogin from '../../../components/auth/OfficeLogin';
import ArrayTools from '../../../helpers/ArrayTools'
import CreateUpdate from '../../../components/brands/CreateUpdate'
import FiltersPaginated from "../../../components/brands/FiltersPaginated";

export default {
  name: 'Brands',
  components: {
    CreateUpdate,
    FiltersPaginated,
    OfficeLogin
  },
  watch: {
    options: {
      handler() {
        this.perPage = this.options.itemsPerPage
        this.pageSelected = this.options.page
        const search = this.search_brand
        if (this.filterPaginated.name!=null || this.filterPaginated.description!=null){
          this.getAllBrands({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,name:this.filterPaginated.name,
            description:this.filterPaginated.description})
        }else{
          if (search!=null && search.trim().length > 0) {
            // this.perPage=-1
            this.getAllBrands({
              paginated: true, page: this.pageSelected, itemsPerPage: this.perPage,
              name: search
            })
          } else {
            this.getAllBrands({paginated: true, page: this.pageSelected, itemsPerPage: this.perPage})
          }
        }

      },
      deep: true,
    }
  },
  data() {
    return {
      search_brand: null,
      drawer: false,
      loading: false,
      dataTable: {page: 1},
      options: {},
      show_options_crud: false,
      show_btn_active: false,
      selectedRows: [],

      itemsBreadcrumbs: [
        {
          text: 'Registros',
          disabled: true,
          href: '',
        }
      ],
      headers: [
        {text: 'NOMBRE', value: 'name'},
        {text: 'DESCRIPCIÓN', value: 'description'},
      ],
    }
  },
  mounted() {
    this.changeFilterPaginated({name:null,description:null})
  },
  computed: {
    ...mapState('brands', [
      'brands',
      'paginated',
      'filterPaginated'
    ])
  },
  methods: {
    ...mapActions('brands', [
      'getBrands',
      'change_status',
      'delete',
      'changeFilterPaginated'
    ]),
    async onPageChange(page) {
      this.loading = true
      await this.getBrands({page: page, paginated: true})
          .then(result => this.loading = false)
          .catch(errr => this.loading = false)
    },
    showOptionCrud() {
      this.show_options_crud = this.selectedRows.length > 0
      if (this.show_options_crud) {
        this.show_btn_active = !this.selectedRows[0].active
      }
    },
    handleSelectItem(selected) {
      if (selected.value) {
        this.selectedRows.push(selected.item)
      } else {
        let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
        if (index_found > -1) {
          this.selectedRows.splice(index_found, 1)
        }
      }
      this.showOptionCrud()
    },
    handleShowCreate() {
      this.$refs.createUpdate.showForm('Nueva marca', 'create')
    },
    handleEdit() {
      let color = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      this.$refs.createUpdate.showForm('Modificar datos de marca', 'update', color)
    },
    handleShowFilters() {
      this.$refs.filters.showForm('Filtrar datos de marcas')
    },
    async getAllBrands(filters) {
      this.loading = true
      this.dataTable.page = 1
      await this.getBrands(filters).then(result => {
        this.loading = false
      })
          .catch(error => {
            this.loading = false
          })
    },
    handleInputSearchByEnter(KeyboardEvent) {
      if (KeyboardEvent.code == 'Enter') {
        this.handleSearch();
      }
    },
    async handleSearch() {
      this.selectedRows = []
      this.loading = true
      this.changeFilterPaginated({name:null,description:null})
      this.showOptionCrud()
      let filters = {}
      this.dataTable.page = 1
      if (this.search_brand.trim().length > 0) {
        filters = {
          name: this.search_brand,
        }
      } else {
        filters = {
          paginated: true
        }
      }
      await this.getBrands(filters)
          .then(result => {
            this.loading = false
          })
          .catch(error => {
                this.loading = false
              }
          )
    },
    async handleChangeStatus() {
      let brand = this.selectedRows[0]
      this.selectedRows = []
      this.showOptionCrud()
      await this.change_status(brand)
    },
    async handleDelete() {
      let brand = this.selectedRows[0]
      await this.delete(brand)
      this.selectedRows = []
      this.showOptionCrud()
    }
  },
  created() {
    // this.getAllBrands({paginated: true});
  }

}
</script>

<style scoped>

</style>